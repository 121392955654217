/* eslint-disable no-new */

import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import KProgress from 'k-progress';
import 'select2';
import 'select2/dist/css/select2.css';

import "bootstrap"
import '../css/application.css';
import "../stylesheets/application";

require('@rails/ujs').start();
require('turbolinks').start();

Vue.use(TurbolinksAdapter);
Vue.use(Chartkick.use(Chart));
Vue.component('k-progress', KProgress);

Vue.component('observer-report', () => import('../components/observer-report'));
Vue.component('show-observer-report', () => import('../components/show-observer-report'));
Vue.component('base-dialog', () => import('../components/base-dialog'));
Vue.component('exercise-pending-dialog', () => import('../components/exercise-pending-dialog'));
Vue.component('exercise-notice-dialog', () => import('../components/exercise-notice-dialog'));
Vue.component('student-notes-dialog', () => import('../components/student-notes-dialog'));
Vue.component('student-optional-comments-dialog', () => import('../components/student-optional-comments-dialog'));
Vue.component('base-search', () => import('../components/base-search'));
Vue.component('input-help-dialog', () => import('../components/input-help'));


document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('vue-app') !== null) {
    new Vue({
      el: '#vue-app',
    });
  }
});

